<template>
  <section class="help--desk-wrapper">
    <section-loader :show="loading" />
    <div class="title--text">
      {{ $t('home.managerSection.form.title') }}
    </div>
    <space-manager-form />
  </section>
</template>

<script>
import SpaceManagerForm from '@/components/help-desk/space-manager/space-manager-form';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  components: {
    SpaceManagerForm,
    SectionLoader,
  },
  computed: {
    loading() {
      return this.$store.state.v2.spaceManagerRequest.loading;
    },
  },
};
</script>
