<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <space-manager />
    </view-skeleton>
  </default-layout>
</template>

<script>
import ViewSkeleton from '@/components/utils/view-skeleton';

import DefaultLayout from '@/layouts/default';
import SpaceManager from '@/components/help-desk/space-manager';

export default {
  components: {
    DefaultLayout,
    ViewSkeleton,
    SpaceManager,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.spaceManager'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  async fetch({ store, route }) {
    const baseUrl = store.state.global.baseUrl;
    let title = '';
    let description = '';
    title = 'Space Manager Rentfix.com - Platform Sewa Properti di Indonesia';
    description =
      'Dapatkan bantuan dari Space Manager Rentfix.com, situs yang membantu Anda melakukan proses sewa properti mulai dari rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di seluruh Indonesia.';
    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
};
</script>
