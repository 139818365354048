<template>
  <div>
    <form @submit.prevent="submitRequest">
      <property-type-section ref="propertyTypeSection" />
      <div class="form--group">
        <div class="left--col">
          <label>
            <span>{{ $t('addListing.rentType') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <div class="choose--first" v-show="rentTypes.length === 0">
            {{ $t('home.managerSection.form.choose') }}
          </div>
          <div class="button--group" data-toggle="buttons" role="group" aria-label="...">
            <rent-type-btn v-for="rentType in rentTypes" :key="rentType.id" :rent-type="rentType" />
          </div>
        </div>
      </div>
      <div class="form--group">
        <div class="left--col">
          <label>
            <span>{{ $t('general.rentDuration') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <div class="choose--first" v-show="rentPeriods.length === 0">
            {{ $t('home.managerSection.form.choose') }}
          </div>
          <div class="button--group" data-toggle="buttons" role="group" aria-label="...">
            <rent-period-btn
              v-for="rentPeriod in rentPeriods"
              :key="rentPeriod.id"
              :rent-period="rentPeriod"
            />
          </div>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('reference') }">
        <div class="left--col">
          <span>{{ $t('home.managerSection.form.reference') }}</span>
        </div>
        <div class="right--col">
          <div
            class="custom-control custom-radio my-2"
            v-for="item in referenceList"
            :key="'reference-' + item.id"
          >
            <input
              type="radio"
              class="custom-control-input"
              :value="item.id"
              :checked="item.checked"
              :id="'reference-' + item.id"
              name="reference[]"
              @input="referenceInputHandler"
            />
            <label class="custom-control-label" :for="'reference-' + item.id">
              <span v-if="item.name">{{ item.name }}</span>
            </label>
            <input
              v-if="!item.name"
              class="basic--input"
              id="otherReference"
              name="otherReference"
              type="text"
              :placeholder="$t('home.managerSection.form.otherReference')"
              v-model="otherReference"
            />
            <div v-if="!item.name"></div>
          </div>
          <span class="val-error" v-if="validation.hasError('reference')">{{
            validation.firstError('reference')
          }}</span>
        </div>
      </div>
      <client-only>
        <search-places ref="searchPlacesRef" />
      </client-only>
      <div class="form--group" :class="{ 'has-error': validation.hasError('otherSpec') }">
        <div class="left--col">
          <label for="other-spec">
            <span>{{ $t('home.managerSection.form.step2.q2') }}</span>
          </label>
        </div>
        <div class="right--col">
          <input
            id="other-spec"
            v-model="otherSpec"
            type="text"
            class="basic--input"
            :placeholder="$t('home.managerSection.placeholder.spec')"
          />
          <span class="val-error" v-if="validation.hasError('otherSpec')">{{
            validation.firstError('otherSpec')
          }}</span>
        </div>
      </div>
      <personal v-if="!loggedIn" ref="personalDataRef" />
      <div class="d-flex justify-center">
        <button type="submit" class="btn btn-primary">SEND</button>
      </div>
    </form>
  </div>
</template>

<script>
const PropertyTypeSection = () =>
  import('@/components/help-desk/space-manager/property-type-section');
const RentTypeBtn = () => import('@/components/help-desk/space-manager/rent-type-btn');
const RentPeriodBtn = () => import('@/components/help-desk/space-manager/rent-period-btn');
const SearchPlaces = () => import('@/components/help-desk/space-manager/search-places');
const Personal = () => import('@/components/help-desk/space-manager/personal');
import { mapGetters } from 'vuex';
import { Validator } from 'simple-vue-validator';

export default {
  name: 'space-manager-form',
  components: {
    PropertyTypeSection,
    RentTypeBtn,
    RentPeriodBtn,
    SearchPlaces,
    Personal,
  },
  computed: {
    ...mapGetters({
      rentTypes: 'v2/spaceManagerRequest/filteredRentTypes',
      rentPeriods: 'v2/spaceManagerRequest/filteredRentPeriods',
      referenceList: 'v2/spaceManagerRequest/referenceList',
      reference: 'v2/spaceManagerRequest/reference',
    }),
    otherSpec: {
      get() {
        return this.$store.state.v2.spaceManagerRequest.otherSpecification;
      },
      set(value) {
        this.$store.commit('v2/spaceManagerRequest/SET_OTHER_SPEC', value);
      },
    },
    otherReference: {
      get() {
        return this.$store.state.v2.spaceManagerRequest.otherReference;
      },
      set(value) {
        this.$store.commit('v2/spaceManagerRequest/SET_OTHER_REF', value);
      },
    },
    selectedReference: {
      get() {
        return this.$store.state.v2.spaceManagerRequest.selectedReference;
      },
      set(value) {
        this.$store.commit('v2/spaceManagerRequest/SET_SELECTED_REF', value);
      },
    },
    loggedIn() {
      return this.$store.state.global.loggedIn;
    },
  },
  mounted() {
    this.$store.commit('v2/spaceManagerRequest/RESTORE_INITIAL_STATE');
    this.$store.dispatch('v2/spaceManagerRequest/getData');
  },
  methods: {
    referenceInputHandler(evt) {
      let reference = this.referenceList.find((item) => {
        return item.id == evt.target.value;
      });
      this.selectedReference = reference;

      if (reference.name) this.otherReference = '';
    },
    async submitRequest() {
      try {
        let validationArray = [
          this.$validate(),
          this.$refs.propertyTypeSection.validate(),
          this.$refs.searchPlacesRef.validate(),
        ];
        if (!this.loggedIn) {
          validationArray.push(this.$refs.personalDataRef.validate());
        }
        const isValid = await Promise.all(validationArray).then(function (results) {
          return (
            results.filter(function (result) {
              return !result;
            }).length === 0
          );
        });
        if (isValid) {
          const result = await this.$store.dispatch('v2/spaceManagerRequest/postRequest');
          if (result) {
            await this.$swal(
              this.$t('home.managerSection.spaceSwal.success'),
              this.$t('home.managerSection.spaceSwal.successMessage'),
              'success',
            );
            this.$router.push('/');
          }
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
  },
  validators: {
    otherSpec(value) {
      return Validator.value(value).maxLength(
        150,
        this.$i18n.t('errors.otherSpec.max', { maxChar: 150 }),
      );
    },
  },
};
</script>
